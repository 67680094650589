<template>
  <div>
    <section class="department-create ly-create">
      <div class="section-inner">
        <div class="section-cont">
          <h2>부서 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>EDUCATION</span>&nbsp;&gt;&nbsp;
            <span>부서 관리</span>
          </p>
          <!-- /.site-path -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
            </colgroup>
            <tr>
              <th scope="row"><label for="department-name">부서명</label></th>
              <td>
                <input
                  type="text"
                  name="department-name"
                  id="department-name"
                  v-model="name"
                />
              </td>
            </tr>
            <tr>
              <th scope="row">사용 여부</th>
              <td class="radio-cell">
                <span>
                  <input
                    type="radio"
                    id="department-use"
                    name="video"
                    value=1
                    v-model="available"
                  />
                  <label for="department-use">사용</label>
                </span>
                <span>
                  <input
                    type="radio"
                    id="department-unuse"
                    name="video"
                    value=0
                    v-model="available"
                  />
                  <label for="department-unuse">미사용</label>
                </span>
              </td>
            </tr>
          </table>
          <!-- /.table -->
          <router-link :to="{ name: 'DepartmentList' }" class="btn-white"
            >목록</router-link
          >
          <button class="btn-blue btn-right" @click="onClickCreate">
            저장
          </button>
          <!-- /.btn -->
        </div>
        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";

export default {
  components: { AppFooter },
  name: "DepartmentCreate",
  data() {
    return {
      name: '',
      available: 1 ,  
    };
  },
  methods: {
    onClickCreate() {
      console.log('onClickCreate =>  ', this.name , this.available  ); 
      var payload = {
        name : this.name , 
        available : this.available 
      }
      this.$store.dispatch("education/req_create_department", payload ).then( result => {
        console.log( 'req_create_department result => ', result );
        this.$alert("등록하였습니다.").then(() => {
          this.$router.push({ name: "DepartmentList" });
        });
      }).catch( err => { 
        console.log( ' error => ', err );
      });
    },
  },

    
};
</script>
<style scoped></style>
