<template>
  <div>
    <section class="success-story-edit ly-edit">
      <div class="section-inner">
        <div class="section-cont">
          <h2>성공사례 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>EDUCATION</span>&nbsp;&gt;&nbsp;
            <span>성공사례 관리</span>
          </p>
          <!-- /.site-path -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
            </colgroup>
            <tr>
              <th scope="row">  
                <label for="title">제목</label>
              </th>
              <td>
                <input type="text" id="title" name="title" v-model="title" />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="sub-title">부제</label>
              </th>
              <td>
                <input
                  type="text"
                  id="sub-title"
                  name="sub-title"
                  v-model="subtitle"
                />
              </td>   
            </tr>
            <tr>
              <th scope="row">내용</th>
              <td>
                <ckeditor
                  :editor="editor"
                  v-model="editorData"
                  :config="editorConfig"
                  class="text-area"
                ></ckeditor>
                <!-- <span class="text-area">
                </span> -->
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="youtube">유튜브 영상</label>
              </th>
              <td class="youtube">
                <input
                  type="text"
                  name="youtube"
                  id="youtube"
                  v-model="youtube_link"
                />
                <span
                  >예)youtube.com/watch?<em>v=MDHjXD5RKH</em>을 입력
                  바랍니다.</span
                >
              </td>
            </tr>
          </table>

          <!-- /.table-->
          <router-link
            :to="{ name: 'SuccessStoryList' }"
            class="btn-white"
            tag="a"
            >목록</router-link
          >
          <button class="btn-blue btn-right" @click="onSubmitModify">
            수정
          </button>
          <!-- /.btn -->
        </div>
        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  props: ["id", "item"],
  components: { AppFooter },
  name: "SuccessStoryEdit",
  data() {
    return {
      title: '',
      subtitle: '',
      youtube_link: '',

      editor: ClassicEditor,
      editorData: '' ,
      editorConfig: {
        // The configuration of the editor.
        toolbar: [],
      },
    };
  }, 
  created() {
    console.log("props id => ", this.id) ;
    console.log("props item => ", this.item) ;
    this.title = this.item.title ;
    this.subtitle = this.item.subtitle ;
    this.editorData = this.item.story ;
    this.youtube_link = this.item.youtube_link ;
  },  
  methods: {
    onSubmitModify() {
      console.log("onSubmitModify. -----");

      const payload = {
        id: this.item.id ,
        title: this.title ,
        subtitle: this.subtitle , 
        story: this.editorData ,
        youtube_link: this.youtube_link  
      };

      this.$store.dispatch("education/req_update_successstory",  payload  )
      .then(( ) => {
        this.$alert("수정하였습니다.").then( () => {
            this.$router.push({ name: "SuccessStoryList" });
        });
      })
      .catch((error) => {
        console.log("error => ", error);
      });

    },

  },
};
</script>
<style lang="scss" scoped>
input[id="youtube"] {
  width: 230px !important;
  display: inline-block !important;
  margin-right: 20px !important;
}
span {
  font-size: 16px;
  em {
    color: #ff4141;
  }
}
</style>
