<template>
  <div>
    <section class="officer-open-recruitment-apply-show ly-show">
      <div class="section-inner">
        <div class="section-cont">
          <h2>사관 &middot; 공채 지원 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>EDUCATION</span>&nbsp;&gt;&nbsp;
            <span>사관 &middot; 공채 지원 관리</span>
          </p>
          <!-- /.site-path -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
            </colgroup>

            <tr>
              <th scope="row">구분</th>
              <td>{{ getTypeName(this.item.type) }}</td>
            </tr>
            <tr>
              <th scope="row">성함</th>
              <td>{{ this.item.name }}</td>
            </tr>
            <tr>
              <th scope="row">이메일</th>
              <td class="email-underline">{{ this.item.email }}</td>
            </tr>
            <tr>
              <th scope="row">연락처</th>
              <td>{{ this.item.mobile_no }}</td>
            </tr>
            <tr>
              <th scope="row">첨부파일</th>
              <td v-if="item.attachment != null">
                <a
                  :href="this.item.attachment.download_url"
                  :download="this.item.attachment.originalname"
                  class="resume-download"
                  target="_blank"
                >
                  {{ this.item.attachment.originalname }}</a
                >&nbsp;&nbsp;<span
                  >[{{ formatSizeUnits(this.item.attachment.size) }}]</span
                >
              </td>
              <td v-else><a> </a>&nbsp;&nbsp;<span></span></td>
            </tr>
          </table>
          <!--/. table -->
          <router-link
            :to="{ name: 'OfficerOpenRecruitmentApplyList' }"
            class="btn-white"
            tag="a"
            >목록</router-link
          >
          <button
            class="btn-icon-del btn-icon-del-s btn-right"
            @click="onConfirmDeleteOne"
          >
            <img src="../../../assets/image/btn-del.png" alt="delete icon" />
            <span>삭제</span>
          </button>
          <!-- /.btn -->
        </div>
        <app-footer />
      </div>
    </section>
  </div>
</template>
  
<script>
import AppFooter from "../../AppFooter.vue";

export default {
  props: ["id", "item"],
  components: { AppFooter },
  name: "OfficerOpenRecruitmentApplyShow",
  data() {
    return {};
  },
  created() {
    console.log("props id => ", this.id);
    console.log("props item => ", this.item);
  },
  methods: {
    getTypeName(type) {
      if (type == 1) return "사관";
      else if (type == 2) return "공채";
      else if (type == 3) return "코디네이터";
      else return "-";
    },
    onSubmitDeleteOne() {
      console.log("onSubmitDeleteOne ==> ");

      this.$store
        .dispatch("education/req_remove_officeropenrecruitment_apply_list", {
          idarr: [this.item.id],
        })
        .then((result) => {
          console.log("result => ", result);
          this.$router.push({ name: "OfficerOpenRecruitmentApplyList" });
        });
    },
  },
};
</script>
