<template>
  <div>
    <section class="officer-open-recruitment-post-edit ly-edit">
      <div class="section-inner">
        <div class="section-cont">
          <h2>사관 &middot; 공채 공고 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>EDUCATION</span>&nbsp;&gt;&nbsp;
            <span>사관 &middot; 공채 공고 관리</span>
          </p>
          <!-- /.site-path -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
            </colgroup>
            <tr>
              <th scope="row">
                <label for="brand-name">제목</label>
              </th>
              <td>
                <input
                  type="text"
                  id="brand-name"
                  name="brand-name"  
                  v-model="title"
                />
              </td>
            </tr>  
            <tr>
              <th scope="row">
                <label for="last-date">마감일</label>
              </th>
              <td>
                <date-picker v-model="due_date" valueType="format" format="YYYY.MM.DD" :default-value="default_due_date" :lang="lang"></date-picker>
              </td>
            </tr>
            <tr>
              <th scope="row">내용</th>
              <td>
                <ckeditor
                  :editor="editor"
                  v-model="editorData"
                  :config="editorConfig"
                  class="text-area"
                ></ckeditor>
                <!-- <span class="text-area">
                </span> -->
              </td>
            </tr>

            <tr>

              <th scope="row">상세 이미지</th>
              <td class="file-cell">
                <div class="attached-file-wrap">
                  <div
                    class="attached-file"
                    v-for="(file, index) in files"
                    v-bind:key="index"
                  >
                    <p v-if="file">{{ file.name }}</p>
                    <p v-else class="file-empty"></p>
                    <file-select v-model="files[index]" v-bind:show-del=" file != null && file.name != null "></file-select>
                  </div>
                </div>

                <span>10MB 이하 업로드 (jpg / png / gif / bmp)</span>
              </td>

            </tr>


            <tr>
              <th scope="row">지원서 양식</th>
              <td class="file-cell">
                <!-- <p v-if="fileMain">{{ fileMain.name }}</p>
                <p v-else class="file-empty"></p>
                <file-select v-model="fileMain" v-bind:show-del=" fileMain != null && fileMain.name != null "></file-select> -->

                <div class="attached-file-wrap">
                  <div
                    class="attached-file"
                    v-for="(file, index) in applicationformFiles"
                    v-bind:key="index"
                  >
                    <p v-if="file"> {{ file.name }}</p>
                    <p v-else class="file-empty"></p>
                    <file-select v-model="applicationformFiles[index]" v-bind:show-del=" file != null && file.name != null "></file-select>
                  </div>

                </div>
                <span>50MB 이하 업로드 (pptx / doc / xlsx / hwp / pdf)</span>


              </td>
            </tr>
          </table>
          <!-- /.table-->
          <router-link
            :to="{ name: 'OfficerOpenRecruitmentPostList' }"
            class="btn-white"
            tag="a"
            >목록</router-link
          >
          <button class="btn-blue btn-right" @click="onSubmitModify">
            수정
          </button>
          <!-- /.btn -->
        </div>
        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import FileSelect from "../..//FileSelect.vue";
import attachments_update_mixin from "../../../mixins/attachments_update_mixin";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  props: ["id", "item"],
  components: { AppFooter, FileSelect , DatePicker },
  name: "OfficerOpenRecruitmentPostEdit",
  mixins: [attachments_update_mixin],
  data() {
    return {
      title: '',
      due_date: '',

      fileMain: null ,
      applicationformFiles: [null, null, null, null, null], 
      files: [null, null, null, null, null] ,
      
      editor: ClassicEditor,
      editorData: '',
      editorConfig: {
        // The configuration of the editor.
        toolbar: [],
      },

      default_due_date: null,
      lang: null, 
    }; 
  },
  created() {
    console.log("props id => ", this.id );
    console.log("props item => ", this.item );
    this.lang = this.datepicker_lang;

    this.title = this.item.title ; 
    this.editorData = this.item.details ; 

    this.attachments = this.item.attachments ; 

    this.item.due_date = this.item.due_date.replace(/\s/g, '');
    this.due_date = this.item.due_date ; 
    console.log('trimmedDateStr => ', this.item.due_date ); 
    this.default_due_date = new Date(this.item.due_date); 
    // 첨부 파일은 표시 셋팅
    this.init_attachments_set();
},
  methods: {

    onClickFile(fileobj) {
      console.log('onClickFile => ', fileobj ); 
      window.open(fileobj.url, '_blank');
    },
    onSubmitModify() {
      console.log("onSubmitModify. -----");
      if( this.due_date == null ) {
        return this.$fire({
          title: "마감일을 선택해주세요",
          text: "",
          type: "warning",
          timer: 2000
        }).then(r => {
          console.log(r.value);
        });
      }
      // 첨부파일 수정이 있음.

      const payload = {
        id: this.item.id,
        due_date: this.due_date ,
        title: this.title, 
        details: this.editorData ,
      };
 
      console.log('payload => ', payload ); 
      this.$store
      .dispatch("education/req_update_officeropenrecruitment_announce",  payload  )
      .then(( ) => {
          this.onSubmitModifyFiles( "recruitment_announce" , "OfficerOpenRecruitmentPostList");
      })
      .catch((error) => {
        console.log("error => ", error);
      });


    },
  },
};
</script>

<style scoped>
.ck-editor__editable_inline {
  min-height: 400px;
  /* max-height: 400px; */
}

/* .ck.ck-editor {
    min-height: 200px !important;
    max-height: 400px !important;
} */
</style>
