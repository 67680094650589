<template>
  <div>
    <section class="officer-open-recruitment-manager-edit ly-edit">
      <div class="section-inner">
        <div class="section-cont">
          <h2>사관 &middot; 공채 지원 담당자 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>EDUCATION</span>&nbsp;&gt;&nbsp;
            <span>사관 &middot; 공채 지원 담당자 관리</span>
          </p>
          <!-- /.site-path -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
            </colgroup>
            <tr>
              <th scope="row">
                <label
                  for="
              manager-name"
                  >담당자</label
                >
              </th>
              <td>
                <input
                  type="text"
                  name="
              manager-name"
                  id="
              manager-name"
                  v-model="name"
                />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label
                  for="
              phone-number"
                  >연락처</label
                >
              </th>
              <td>
                <input
                  type="text"
                  name="
              phone-number"
                  id="
              phone-number"
                  v-model="mobile_no"
                />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label
                  for="
              email"
                  >이메일</label
                >
              </th>
              <td>
                <input
                  type="text"
                  name="
              email"
                  id="
              email"
                  v-model="email"
                />
              </td>
            </tr>
          </table>
          <!-- /.table-->
          <router-link
            :to="{ name: 'OfficerOpenRecruitmentManagerList' }"
            class="btn-white"
            tag="a"
            >목록</router-link
          >
          <button class="btn-blue btn-right" @click="onSubmitModify">
            수정
          </button>
          <!-- /.btn -->
        </div>

        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";
import mananger_handle_mixin from '../../../mixins/managerHandle_mixin'; 

export default {
  props: ["id", "item"],
  components: { AppFooter },
  name: "OfficerOpenRecruitmentManagerEdit",
  mixins: [mananger_handle_mixin],
  data() {
    return {
      taskpart: 'recruitment',

    };
  },
  created() {
    console.log("props id => ", this.id);
    this.name       = this.item.name ; 
    this.mobile_no  = this.item.mobile_no;
    this.email      = this.item.email ;
  },
  methods: {
    onClickDelete() {
      console.log("onClickDelete. -----");
    },
    onSubmitModify() {
      console.log("onSubmitModify. -----");
      this.reqUpdateManager( 
        {
          taskpart: this.taskpart ,
          id: this.item.id, 
          name: this.name ,
          mobile_no : this.mobile_no,
          email: this.email 
        }
      );
    },
  },
};
</script>
