<template>
  <div>
    <section class="officer-open-recruitment-post-create ly-create">
      <div class="section-inner">
        <div class="section-cont">
          <h2>사관 &middot; 공채 공고 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>EDUCATION</span>&nbsp;&gt;&nbsp;
            <span>사관 &middot; 공채 공고 관리</span>
          </p>
          <!-- /.site-path -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
            </colgroup>
            <tr>
              <th scope="row">
                <label for="brand-name">제목</label>
              </th>
              <td>
                <input
                  type="text"   
                  id="brand-name"
                  name="brand-name"
                  v-model="title"
                />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="last-date">마감일</label>
              </th>
              <td>

              <date-picker v-model="due_date" valueType="format" format="YYYY.MM.DD" :lang="lang"></date-picker>

              </td>
            </tr>
            <tr>
              <th scope="row">내용</th>
              <td>
                <ckeditor
                  :editor="editor"
                  v-model="editorData"
                  :config="editorConfig"
                  class="text-area"
                ></ckeditor>
                <!-- <span class="text-area">
                </span> -->
              </td>
            </tr>

            <tr>

              <th scope="row">상세 이미지</th>
              <td class="file-cell">
                <div class="attached-file-wrap">
                  <div
                    class="attached-file"
                    v-for="(file, index) in files"
                    v-bind:key="index"
                  >
                    <p v-if="file">{{ file.name }}</p>
                    <p v-else class="file-empty"></p>
                    <file-select v-model="files[index]" ></file-select>
                  </div>
                </div>

                <span>10MB 이하 업로드 (jpg / png / gif / bmp)</span>
              </td>

            </tr>
            <tr>
              <th scope="row">지원서 양식</th>
              <td class="file-cell">

                <div class="attached-file-wrap">
                  <div
                    class="attached-file"
                    v-for="(file, index) in applicationformFiles"
                    v-bind:key="index"
                  >
                    <p v-if="file">{{ file.name }}</p>
                    <p v-else class="file-empty"></p>
                    <file-select v-model="applicationformFiles[index]" ></file-select>
                  </div>
                </div>

                <!-- <p v-if="applicationform">{{ applicationform.name }}</p>
                <p v-else class="file-empty"></p>
                <file-select v-model="applicationform"></file-select> -->
                <span>50MB 이하 업로드 (pptx / doc / xlsx / hwp / pdf)</span> 

              </td>
            </tr>
          </table>
          <!-- /.table-->
          <router-link
            :to="{ name: 'OfficerOpenRecruitmentPostList' }"
            class="btn-white"
            tag="a"
            >목록</router-link
          >
          <button class="btn-blue btn-right" @click="onClickCreate">
            저장
          </button>
          <!-- /.btn -->
        </div>
        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import FileSelect from "../..//FileSelect.vue";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: { AppFooter, FileSelect, DatePicker },
  name: "OfficerOpenRecruitmentPostCreate",
  data() {
    return {
      title: '',
      details: '', 

      // applicationform: null ,
      applicationformFiles: [null, null, null, null, null], 

      files: [null, null, null, null, null] ,
      
      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        // The configuration of the editor.
        toolbar: [],
      },

      // dropdown_due_date: '',
      // minDate: null ,
      due_date: null,
      lang: null, 
    };
  },
  created() {
    this.lang = this.datepicker_lang;
  },
  methods: {
    onClickCreate() {
      
      if( this.due_date == null ) {
        return this.$fire({
          title: "마감일을 선택해주세요",
          text: "",
          type: "warning",
          timer: 2000
        }).then(r => {
          console.log(r.value);
        });
      }

      if( this.editorData.length == 0 ) {
        return this.$fire({
          title: "내용을 입력해주세요.",
          text: "",
          type: "warning",
          timer: 2000
        }).then(r => {
          console.log(r.value);
        });

      }

      console.log("in onClickCreate ==>  name ", this.title );
      console.log("in onClickCreate ==>  due_date ", this.due_date );
      console.log("in onClickCreate ==>  details ", this.editorData );


      let formData = new FormData();
      formData.append("title", this.title );
      formData.append("due_date", this.due_date );
      formData.append("details", this.editorData );

      var count = 0 ; 
      for (let attfile of this.files) {
        if( attfile != null && attfile != undefined ) {
          var fieldname = "files[" + count +"]"; 
          formData.append(fieldname, attfile );
        }
        count++;
      }

      // if( this.applicationform != null ) formData.append("applicationform", this.applicationform);
      count = 0 ; 
      for (let attachfile of this.applicationformFiles) {
        if( attachfile != null && attachfile != undefined ) {
          var applicationformFieldName = "applicationform[" + count +"]"; 
          formData.append(applicationformFieldName, attachfile );
        }
        count++;
      }


      this.$store.dispatch("education/req_create_officeropenrecruitment_announce", formData  ).then( result => {
        console.log( 'req_create_officeropenrecruitment_announce result => ', result );
        this.$alert("등록하였습니다.").then(() => {
          this.$router.push({ name: "OfficerOpenRecruitmentPostList" });
        });
      }).catch( err => { 
        console.log( ' error => ', err ); 
      });


    },


  },
};
</script>
