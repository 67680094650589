<template>
  <div>
    <section class="picture-and-video-create ly-create">
      <div class="section-inner">
        <div class="section-cont">
          <h2>사진 및 졸업동영상 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>EDUCATION</span>&nbsp;&gt;&nbsp;
            <span>사진 및 졸업동영상 관리</span>
          </p>
          <!-- /.site-path -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
            </colgroup>
            <tr>
              <th scope="row"><label for="title">제목</label></th>
              <td>
                <input type="text" name="title" id="title" v-model="title" />
              </td>
            </tr>
            <tr> 
              <th scope="row"><label for="admin-id">유튜브 영상</label></th>
              <td class="youtube">
                <input type="text" name="youtube" id="youtube" v-model="youtube_link"/>
                <span
                  >예)youtube.com/watch?<em>v=MDHjXD5RKH</em>을 입력
                  바랍니다.</span
                >
              </td>
            </tr>
          </table>
          <!-- /.table-->
          <router-link
            :to="{ name: 'PictureAndVideoList' }"
            class="btn-white"
            tag="a"
            >목록</router-link
          >
          <button class="btn-blue btn-right" @click="onClickCreate">
            저장
          </button>
          <!-- /.btn -->
        </div>
        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";

export default {
  components: { AppFooter },
  name: "PictureAndVideoCreate",
  data() {
    return {
      title: "",
      youtube_link: "",
    };
  },
  methods: {

    onClickCreate() {

      var payload = {
        title : this.title , 
        youtube_link : this.youtube_link ,
      }
      console.log("in onClickCreate ==> payload ", payload );

      this.$store.dispatch("education/req_create_pictureandvideo", payload  ).then( result => {
        console.log( 'req_create_pictureandvideo result => ', result );
        this.$alert("등록하였습니다.").then(() => {
          this.$router.push({ name: "PictureAndVideoList" });
        });
      }).catch( err => { 
        console.log( ' error => ', err ); 
      });

    },


  },
};
</script>
<style lang="scss" scoped>
input[id="youtube"] {
  width: 230px !important;
  display: inline-block !important;
  margin-right: 20px !important;
}
span {
  font-size: 16px;
  em {
    color: #ff4141;
  }
}
</style>
