<template>
  <div>
    <section class="officer-open-recruitment-manager-list ly-list">
      <div class="section-inner">
        <div class="section-cont">
          <h2>사관 &middot; 공채 지원 담당자 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>EDUCATION</span>&nbsp;&gt;&nbsp;
            <span>사관 &middot; 공채 지원 담당자 관리</span>
          </p>
          <!-- /.site-path -->
          <p class="total-post">전체 게시물 <span>{{ getDataTotalCount }}</span>개</p>
          <!-- /.total-post -->
          <search-input :searchHandler="onSearch" />
          <!-- /.search -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
              <col class="col3" />
              <col class="col4" />
              <col class="col5" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col" class="check-cell">
                  <label for="" class="hidden">전체체크</label>
                  <input
                    type="checkbox"
                    @click="check()"
                    v-model="checkAllValue"
                  />
                  <div class="chk-icon-wrap">
                    <img
                      class="chk-icon"
                      src="@/assets/image/icon-form-checkbox.jpg"
                      alt="icon checkbox"
                    />
                  </div>
                </th>
                <th scope="col">No.</th>
                <th scope="col">담당자</th>
                <th scope="col">연락처</th>
                <th scope="col">이메일</th>
              </tr>
            </thead>
            <tbody>
              
              <tr v-for="item in getData" v-bind:key="item.id">
                <td class="check-cell">
                  <label for="" class="hidden"
                    >사관 공채 지원 담당자 체크</label
                  >
                  <input
                    type="checkbox"
                    v-model="checkedValues"
                    :value="item.id"
                  />
                  <div class="chk-icon-wrap">
                    <img
                      class="chk-icon"
                      src="@/assets/image/icon-form-checkbox.jpg"
                      alt="icon checkbox"
                    />
                  </div>
                </td>
                <td>
                  <router-link
                    :to="{ name: 'OfficerOpenRecruitmentManagerEdit' , params: { id: item.id, item: item }}"
                  >
                    {{item.id}}
                  </router-link>
                </td>
                <td>
                  <router-link
                    :to="{ name: 'OfficerOpenRecruitmentManagerEdit' , params: { id: item.id, item: item }}"
                  >
                    {{item.name}}
                  </router-link>
                </td>
                <td>
                  <router-link
                    :to="{ name: 'OfficerOpenRecruitmentManagerEdit' , params: { id: item.id, item: item }}"
                  >
                    {{item.mobile_no}}
                  </router-link>
                </td>
                <td>
                  <router-link
                    :to="{ name: 'OfficerOpenRecruitmentManagerEdit' , params: { id: item.id, item: item }}"
                  >
                    {{item.email}}
                  </router-link>
                </td>
              </tr>

            </tbody>
          </table>
          <!-- /.table-->
          <div class="page">
            <paginate
              :pageCount="totalPages"
              :pageRange="10"
              :clickHandler="handlePageSelected"
            ></paginate>
          </div>
          <!-- /.paginate -->
          <div class="btn-wrap">
            <button class="btn-icon-del" @click="onConfirmDelete">
              <img src="../../../assets/image/btn-del.png" alt="delete icon" />
              <span>선택삭제</span>
            </button>
            <router-link
              :to="{ name: 'OfficerOpenRecruitmentManagerCreate' }"
              class="btn-blue"
              >등록</router-link
            >
          </div>
          <!-- /.btn-wrap -->
        </div>

        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";
import Paginate from "../../Paginate.vue";
import mananger_handle_mixin from '../../../mixins/managerHandle_mixin'; 
import SearchInput from "../../SearchInput.vue";

export default {
  components: { AppFooter, Paginate , SearchInput},
  mixins: [mananger_handle_mixin],
  name: "OfficerOpenRecruitmentManagerList",
  data() {
    return {
      taskpart: 'recruitment' ,
    };
  },
  computed: {

  },
  created() {
    console.log('OfficerOpenRecruitmentManagerList ==> ' ) ;
    this.loadManagerData({
            taskpart: this.taskpart ,
            page: 1, 
            size: this.PAGE_SIZE,
          }) ;
  },
  methods: {
    onSearch(text) {
        this.searchtext = text ; 
        this.loadManagerData({
            taskpart: this.taskpart ,
            page: 1, 
            size: this.PAGE_SIZE,
            searchtext:  this.searchtext ,
          }) ;
    },
    handlePageSelected(page) {
      console.log("handlePageSelected => ", page) ;
      var payload = {
            taskpart: this.taskpart ,
            page: page, 
            size: this.PAGE_SIZE,
            searchtext:  this.searchtext ,
          }; 
      this.loadManagerData(payload) ;
    },
    onSubmitDelete() {
      console.log("onSubmitDelete ==> ", this.checkedValues);
      if (this.checkedValues.length > 0) {
        this.removeManagerList(); 
      }
    },
  },
};
</script>
<style lang="scss" scoped>
table {
  .col1 {
    width: 64px;
  }
  .col2 {
    width: 91px;
  }
}
</style>
 