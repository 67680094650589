export default {
    
    data() {
        return {
          manager_list: [] ,
          manager_total_count: 0 ,
          name: "",
          mobile_no: "",
          email: "",
        };
    },
    computed: {

      getData: function() {
        return this.manager_list; 
      },
      getDataTotalCount: function() {
        return this.manager_total_count; 
      },
  
    },
    methods: {
        loadManagerData( payload ) {
          this.$store.dispatch("req_manager_list", payload ).then( result => {
            console.log( 'req_manager_list result => ', result );
            this.manager_list = result.data ; 
            this.manager_total_count = result.total_count  ; 
          }).catch( err => {
            console.log( ' error => ', err );
          });
        },
        removeManagerList( ) {
          this.$store.dispatch("req_remove_manager_list", {
            idarr: this.checkedValues,
            taskpart: this.taskpart ,
          }).then( (result) => {
            this.checkedValues = [];
            this.manager_list = result.data ; 
            this.manager_total_count = result.total_count  ; 
          });
        },
        reqCreateManager( payload , tonext ) {

          this.$store
          .dispatch("req_create_manager", payload )
          .then(( ) => {
            this.$alert("등록하였습니다.").then( () => {
              if( tonext != null && tonext != undefined ) this.$router.push({ name: tonext });
            });
          })
          .catch((error) => {
            console.log("error => ", error);
          });

        },
        reqUpdateManager( payload, tonext) {
          this.$store
          .dispatch("req_update_manager", payload )
          .then(( ) => {
            this.$alert("수정하였습니다.").then( () => {
              if( tonext != null && tonext != undefined ) {
                this.$router.push({ name: tonext });
              } 
            });
          })
          .catch((error) => {
            console.log("error => ", error);
          });
        }
    },

}
