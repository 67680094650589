<template>
  <div>
    <section class="department-list ly-list">
      <div class="section-inner">
        <div class="section-cont">
          <h2>부서 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>EDUCATION</span>&nbsp;&gt;&nbsp;
            <span>부서 관리</span>
          </p>
          <!-- /.site-path -->
          <p class="total-post">전체 게시물 <span>{{ getDataTotalCount }}</span>개</p>
          <!-- /.total-post -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
              <col class="col3" />
              <col class="col4" />
              <col class="col5" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col" class="check-cell">
                  <label for="" class="hidden">전체체크</label>
                  <input
                    type="checkbox"
                    @click="check()"
                    v-model="checkAllValue"
                  />
                  <div class="chk-icon-wrap">
                    <img
                      class="chk-icon"
                      src="@/assets/image/icon-form-checkbox.jpg"
                      alt="icon checkbox"
                    />
                  </div>
                </th>
                <th scope="col">No.</th>
                <th scope="col">부서</th>
                <th scope="col">순번</th>
                <th scope="col">사용여부</th>
              </tr>
            </thead>
            <tbody>

              <tr>
                <td></td>
                <td></td>
                <td>
                  <router-link  to="">
                    연구개발확산본부
                  </router-link>
                </td>
                <td class=""></td>
                <td></td>
              </tr>

              <tr v-for="(item, index) in getData" v-bind:key="item.id">
                <td class="check-cell">
                  <label for="" class="hidden">부서 체크</label>
                  <input
                    type="checkbox"
                    v-model="checkedValues"
                    :value="item.id"
                  />
                  <div class="chk-icon-wrap">
                    <img
                      class="chk-icon"
                      src="@/assets/image/icon-form-checkbox.jpg"
                      alt="icon checkbox"
                    />
                  </div>
                </td>
                <td>{{item.id}}</td>
                <td>
                  <router-link :to="{ name: 'DepartmentEdit' , params: { id: item.id, item: item } }">
                    {{item.name}}
                  </router-link>
                </td>
                <td class="order">
                  <button @click="onOrderChangeTop(item, index)">
                    <img
                      src="../../../assets/image/icon-caret-double-top.png"
                      alt=""
                    />
                  </button>
                  <button @click="onOrderChangeUp(item, index)">
                    <img
                      src="../../../assets/image/icon-caret-top.png"
                      alt=""
                    />
                  </button>
                  <button @click="onOrderChangeDown(item, index)">
                    <img
                      src="../../../assets/image/icon-caret-top.png"
                      alt=""
                    />
                  </button>
                  <button @click="onOrderChangeBottom(item, index)">
                    <img
                      src="../../../assets/image/icon-caret-double-top.png"
                      alt=""
                    />
                  </button>
                </td>
                <td>{{item.available == 1 ? 'O': 'X'}} </td>
              </tr>
                 
            </tbody>
          </table>

          <!-- /.table-->
          <!-- <div class="page">
            <paginate
              :pageCount="totalPages"
              :pageRange="10"
              :clickHandler="handlePageSelected"
            ></paginate>
          </div> -->
          <!-- /.paginate -->

          <div class="btn-wrap">

            <button class="btn-icon-del" @click="onConfirmDelete">
              <img src="../../../assets/image/btn-del.png" alt="delete icon" />
              <span>선택삭제</span>
            </button>
            <router-link :to="{ name: 'DepartmentCreate' }" class="btn-blue"
              >등록</router-link
            >
          </div>
          <!-- /.btn-wrap -->
        </div>
        <app-footer />
      </div>
    </section>
  </div>
</template>  
 
<script>
import AppFooter from "../../AppFooter.vue";
// import Paginate from "../../Paginate.vue";

export default {
  // components: { AppFooter, Paginate },
  components: { AppFooter },
  name: "DepartmentList",
  data() {
    return {
      data_list: [ ] ,
      data_total_count: 0 ,

      get TOP_INDEX() {
        return 0;  // 
      },

      current_page : 1,
      // pagesize:20 ,

    };
  },  
  computed: {
    getData: function() {
      return this.data_list.filter( el => el.name != '연구개발확산본부'); 
    },
    getDataTotalCount: function() {
      return this.data_total_count ; 
    },
    // totalPages: function () {
    //   return this.calPageCount(this.getDataTotalCount, this.pagesize );
    // },
    BOTTOM_INDEX() {
        return this.data_list.length;  // 
    },
  },
  created() {
    this.current_page=1;
    this.loadData({
      // page: 1,
      // size: this.pagesize,
    }) ;
  },   
  methods: {  
    generageOrderingList() {
      var arrlist = [];
      // var offset = (this.current_page - 1 ) * this.pagesize + 1  ; 
      var offset = 1; 
      for(  let[ index , department ]  of this.data_list.entries() ) {
        arrlist.push( {
          id: department.id,
          order: offset + index , 
          name: department.name,
          available: department.department,
        });
      }
      return arrlist ; 
    },
    // printDepartList() {
    //     var offset = (this.current_page - 1 ) * this.pagesize + 1  ; 
    //     for(  let[ index , department ]  of this.data_list.entries() ) {
    //       console.log( "index : " , offset + index ,   " department ", department.id , department.order  , department.name ); 
    //     }
    // },
    onOrderChangeTop( item , cindex ) {
      console.log("onOrderChangeTop => item, index ", item, cindex);
      console.log("this.data_list => ", this.data_list); 

      if( cindex > this.TOP_INDEX ) {
        // 이동 가능. 
        // data_list 으로 부터 1개 삭제함, splice의 return 값은 array이다.
        const slicedItemList = this.data_list.splice( cindex , 1 );
        console.log("targetItem => ", slicedItemList); 
        console.log("this.data_list => ", this.data_list); 
        
        this.data_list.splice(this.TOP_INDEX , 0, slicedItemList[0]);

        // this.printDepartList(); 
        let newOrderedList = this.generageOrderingList(); 
        this.updateData({
          targetlist: newOrderedList
        });


      }
      else {
        // 이동 불가. 이미 TOP 
        console.log( "이미 최상위");
      }
    },
    onOrderChangeUp( item , cindex ) {

      console.log("onOrderChangeUp => item, index ", item, cindex);

      if( cindex > this.TOP_INDEX ) {
        // 이동 가능. 
        const slicedItemList = this.data_list.splice( cindex , 1 );
        console.log("targetItem => ", slicedItemList); 
        console.log("this.data_list => ", this.data_list); 
        var upIndex = cindex - 1 ; 
        this.data_list.splice( upIndex , 0 , slicedItemList[0] ) ;

        let newOrderedList = this.generageOrderingList(); 
        this.updateData({
          targetlist: newOrderedList
        });

      }
      else {
        // 이동 불가. 이미 TOP 
        console.log( "이미 최상위");
      }

    },
    onOrderChangeDown( item , cindex ) {
      console.log("onOrderChangeDown => item, index ", item, cindex);
      
      if( cindex < this.BOTTOM_INDEX-1 ) {
        // 이동 가능. 
        const slicedItemList = this.data_list.splice( cindex , 1 );
        console.log("targetItem => ", slicedItemList); 
        console.log("this.data_list => ", this.data_list); 
        var downIndex = cindex +1 ; 
        this.data_list.splice( downIndex , 0 , slicedItemList[0] ) ;

        let newOrderedList = this.generageOrderingList(); 
        this.updateData({
          targetlist: newOrderedList
        });

      }
      else {
        // 이동 불가. 이미 TOP 
        console.log( "이미 최하위");
      }

    },
    onOrderChangeBottom( item , cindex ) {
      console.log("onOrderChangeBottom => item, index ", item, cindex);

      if( cindex < this.BOTTOM_INDEX ) {
        // 이동 가능. 
        // data_list 으로 부터 1개 삭제함, splice의 return 값은 array이다.
        const slicedItemList = this.data_list.splice( cindex , 1 );
        console.log("targetItem => ", slicedItemList); 
        console.log("this.data_list => ", this.data_list); 
        
        this.data_list.splice(this.BOTTOM_INDEX , 0, slicedItemList[0]);

        let newOrderedList = this.generageOrderingList(); 
        this.updateData({
          targetlist: newOrderedList
        });
      }
      else {
        // 이동 불가. 이미 TOP 
        console.log( "이미 최하위");
      }

    },
    updateData( payload ) {
      // eslint-disable-next-line no-unused-vars
      this.$store.dispatch("education/req_update_department_order_list", payload ).then( result => {
        // console.log( 'req_update_department_order_list result => ', result );
      }).catch( err => { 
        console.log( ' error => ', err );
      });
    },
    loadData( payload ) {
      this.$store.dispatch("education/req_department_list", payload ).then( result => {
        // console.log( 'req_department_list result => ', result );
        // ***** 연구개발확산보부는 고정이므로 제외 
        this.data_list = result.data.filter( el => el.name != '연구개발확산본부'); 
        this.data_total_count = result.total_count  ; 

        console.log( 'this.data_list  => ', this.data_list  );

      }).catch( err => { 
        console.log( ' error => ', err );
      });
    },
    handlePageSelected(page) {
      this.current_page = page;
      console.log("handlePageSelected => ", page); 
      this.loadData( {
        page: page,
        size: this.pagesize,
      });     
    },
    onSubmitDelete()  {
      console.log("onSubmitDelete ==> ", this.checkedValues);
      if (this.checkedValues.length > 0) {        

        this.$store.dispatch("education/req_remove_department_list", {
          idarr: this.checkedValues,
        }).then( (result) => {
          this.checkedValues = [];
          // ***** 연구개발확산보부는 고정이므로 제외 
          this.data_list = result.data.filter( el => el.name != '연구개발확산본부');
          this.data_total_count = result.total_count  ; 
        });

      }
    },
  },
};
</script>
  
  
<style lang="scss" scoped>
table {
  .col1 {
    width: 64px;
  }

  tbody tr:nth-child(1) {
    background-color: #f5f5f5;
  }

  .order {
    display: flex;
    justify-content: center;
    padding: 6.7px 0;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #ccc;
      padding: 11px;
      &:nth-child(3),
      &:nth-child(4) {
        transform: rotate(180deg);
      }
      img {
        width: 19px;
        height: 19px;
      }
    }
  }
}


</style>
