<template>
  <div>
    <section class="group-create ly-create">
      <div class="section-inner">
        <div class="section-cont">
          <h2>조직 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>EDUCATION</span>&nbsp;&gt;&nbsp;
            <span>조직 관리</span>
          </p>
          <!-- /.site-path -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
            </colgroup>
            <tr>
              <th scope="row">
                <label for="admin-level">부서 선택</label>
              </th>
              <td>
                <span>
                  <select
                    name="admin-level"
                    id="admin-level"
                    v-model="department"
                  >
                    <option
                      v-for="item in getDepartmentList"
                      v-bind:key="item.id"
                      :value="item"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </span>
              </td>
            </tr>
            <tr>
              <th scope="row"><label for="name">성함</label></th>
              <td>
                <input type="text" name="name" id="name" v-model="name" />
              </td>
            </tr>
            <tr>
              <th scope="row"><label for="position">직함</label></th>
              <td>
                <input
                  type="text"
                  name="position"
                  id="position"
                  v-model="title"
                />
              </td>
            </tr>
            <tr>
              <th scope="row"><label for="admin-name">이력</label></th>
              <td>
                <ckeditor
                  :editor="editor"
                  v-model="editorData"
                  :config="editorConfig"
                  class="text-area"
                ></ckeditor>
              </td>
            </tr>
            <tr>
              <th scope="row">이미지</th>
              <td class="attached-file file-cell">
                <p v-if="file">{{ file.name }}</p>
                <p v-else class="file-empty"></p>
                <file-select v-model="file"></file-select>
                <span
                  >사이즈 : 250 x 300px / 용량 : 10MB 이하 업로드 (jpg / png /
                  gif / bmp)</span
                >
              </td>
            </tr>
            <tr>
              <th scope="row"><label for="group-num">노출 순번</label></th>
              <td>
                <input
                  type="text"
                  name="group-num"
                  id="group-num"
                  v-model="order"
                />
                <span>숫자가 낮을수록 빠른 순으로 표기 됩니다.</span>
              </td>
            </tr>
          </table>
          <!-- /.table-->
          <router-link :to="{ name: 'GroupList' }" class="btn-white" tag="a"
            >목록</router-link
          >
          <button class="btn-blue btn-right" @click="onClickCreate">
            저장
          </button>
          <!-- /.btn -->
        </div>
        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import FileSelect from "../..//FileSelect.vue";
// import axios from "@/api/axios-auth";

export default {
  components: { AppFooter, FileSelect },
  name: "GroupCreate",
  data() {
    return {
      file: null,

      name: "",
      title: "",
      order: null,
      department: null,

      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        // The configuration of the editor.
        toolbar: [],
      },

      department_list: [],
    };
  },
  computed: {
    getDepartmentList: function () {
      return this.department_list;
    },
  },
  created() {
    // this.fileMain = {name: '1547f7d0c5799c.jpg'};

    // 부서정보 데이터 요청
    this.$store
      .dispatch("education/req_department_list", {})
      .then((result) => {
        console.log("req_department_list result => ", result);
        this.department_list = result.data;
      })
      .catch((err) => {
        console.log(" error => ", err);
      });
  },
  methods: {
    onClickCreate() {
      console.log("in onClickCreate ==>  name ", this.name);
      console.log("in onClickCreate ==>  title ", this.title);
      console.log("in onClickCreate ==>  groupNum ", this.order);
      console.log("in onClickCreate ==>  job_history ", this.editorData);

      console.log("in onClickCreate ==>  file ", this.file);

      let formData = new FormData();
      formData.append("name", this.name);
      formData.append("title", this.title);
      formData.append("order", this.order);
      formData.append("department_id", this.department.id);
      formData.append("job_history", this.editorData);

      if (this.file != null) formData.append("file", this.file);

      this.$store
        .dispatch("education/req_create_employee", formData)
        .then((result) => {
          console.log("req_create_employee result => ", result);
          this.$alert("등록하였습니다.").then(() => {
            this.$router.push({ name: "GroupList" });
          });
        })
        .catch((err) => {
          console.log(" error => ", err);
        });
    },
  },
};
</script>
<style lang="scss" scoped></style>
<style scoped>
select {
  width: 250px !important;
}
input[name="group-num"] {
  width: 150px !important;
  display: inline-block !important;
  margin-right: 20px !important;
}
input[name="group-num"] + span {
  color: #ff4141;
}
span {
  font-size: 16px;
}
</style>
